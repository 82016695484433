import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components'
import tw from 'tailwind.macro'; 
import { handleAuthentication } from '../utils/auth.service';
import Layout from '../components/shared/layout'

export default () => {

  handleAuthentication(() => navigate('/'));

  return (
    <Layout>
      <MsgStyle>Logging you in ...</MsgStyle>
    </Layout>
  );
};

const MsgStyle = styled.div `${tw`text-center text-3xl p-32 text-gray-700`}`